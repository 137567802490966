/* General App Styling */
body {
  background-color: #121212;
  color: #e0e0e0;
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

a {
  color: #bb86fc;
  text-decoration: none;
}

a:hover {
  color: #e0e0e0;
}

/* Custom Scrollbar Styling */
body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-track {
  background: #1f1f1f;
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb {
  background: #3a3a3a;
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Profile Section */
.profile-section {
  display: flex;
  align-items: center;
  margin: 2rem;
  margin-top: 4rem;
  margin-bottom: 6rem;
}

.profile-left {
  flex: 1;
  text-align: center;
}

.profile-right {
  flex: 2;
  padding-left: 2rem;
}

.profile-photo {
  border-radius: 50%;
  width: 200px;
  height: 200px;
  object-fit: cover;
}

.resume-download {
  margin-top: 1rem;
  display: inline-block;
  color: #bb86fc;
}

.resume-download:hover {
  color: #e0e0e0;
}

.contact-info {
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
}

.contact-icon {
  color: #bb86fc;
  transition: color 0.2s;
}

.contact-icon:hover {
  color: #e0e0e0;
}

/* Get to Know Me Section */
.get-to-know-me {
  padding: 2rem;
  margin-top: 6rem;
  background-color: #1a1a1a;
  border-radius: 10px;
  margin: 2rem;
  text-align: center;
}

.get-to-know-me h2 {
  color: #ffffff;
  margin-bottom: 1rem;
}

.get-to-know-me p {
  font-size: 1rem;
  line-height: 1.6;
  color: #e0e0e0;
}

.pictures-row {
  display: flex;
  justify-content: space-around;
  gap: 1rem;
  margin-top: 1.5rem;
}

.get-to-know-me-image-wrapper {
  position: relative;
  text-align: center;
}

.get-to-know-me-image {
  width: 300px;
  height: 225px;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s;
}

.get-to-know-me-image:hover {
  transform: scale(1.05);
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
}

.image-caption {
  margin-top: 0.5rem;
  font-size: 0.9rem;
  color: #a0a0a0;
}

/* Popup Image Modal */
.image-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s ease-in-out;
}

.image-popup-overlay.visible {
  visibility: visible;
  opacity: 1;
}

.image-popup-content {
  position: relative;
  max-width: 50vw; /* Adjusts width relative to the viewport */
  max-height: 70vh;
}

.image-popup-content img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.image-popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  color: #ffffff;
  font-size: 1.5rem;
  border: none;
  cursor: pointer;
}

.image-popup-content img.landscape {
  max-width: 80%; /* Make horizontal images smaller */
  max-height: 60%; /* Ensure it doesn’t exceed viewport */
}

/* Project Grid Section */
.project-grid-section {
  padding: 2rem;
}

.filter-options {
  margin-bottom: 1rem;
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 1rem;
}

#category-filter, #language-filter {
  padding: 0.5rem;
  border-radius: 5px;
  background-color: #1f1f1f;
  color: #bb86fc;
}

.project-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  padding: 2rem;
}

.project-card-link {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.project-card {
  background-color: #1f1f1f;
  padding: 1rem;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s;
}

.project-card:hover {
  transform: scale(1.05);
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
}

.project-card img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.project-card h3 {
  margin: 1rem 0 0.5rem 0;
}

.project-card p {
  margin: 0 0 1rem 0;
}

.project-card .project-details {
  margin-top: 0.5rem;
  font-size: 0.9rem;
  color: #a0a0a0;
}

.project-card .project-category {
  font-weight: bold;
  margin-top: 0.5rem;
  color: #bb86fc;
}

.project-card .project-languages {
  margin-top: 0.25rem;
  font-size: 0.85rem;
  color: #a0a0a0;
}

/* Project Progress Bar */
.project-progress {
  height: 10px;
  width: 100%;
  background-color: #3a3a3a;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 0.5rem;
}

.project-progress-bar {
  height: 100%;
  background-color: #bb86fc;
  width: 0%; /* Set dynamically based on project progress */
  transition: width 0.3s ease-in-out;
}

.project-progress-percentage {
  margin-top: 0.5rem;
  font-size: 0.85rem;
  color: #bb86fc;
  font-weight: bold;
  text-align: center;
}

/* Project Page Pop-Up */
.project-popup {
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -10%);
  background-color: #1f1f1f;
  padding: 3rem;
  border-radius: 12px;
  width: 90%;
  max-width: 1100px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.6);
  z-index: 1000;
}

.project-content {
  position: relative;
}

.back-button {
  background: none;
  color: #bb86fc;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.back-button:hover {
  color: #e0e0e0;
}

.project-image-small {
  width: 100%;
  max-width: 500px;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.additional-image {
  width: 100%;
  max-width: 300px;
  margin-top: 1rem;
  border-radius: 8px;
}

/* Footer */
.footer {
  text-align: center;
  padding: 1rem;
  background-color: #1f1f1f;
  position: relative;
  bottom: 0;
  width: 100%;
}

/* Mobile Responsive Styles */
@media (max-width: 480px) {
  .profile-section {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .profile-left,
  .profile-right {
    padding-left: 0;
    margin-bottom: 1rem;
  }

  .contact-info {
    justify-content: center;
  }

  .pictures-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    margin-top: 1.5rem;
  }

  .get-to-know-me-image {
    width: 100%;
    height: auto;
  }

  .image-popup-content {
    max-width: 60%;
    max-height: 80%;
  }

  .filter-options {
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }

  .project-grid {
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
    padding: 1rem;
  }

  .project-popup {
    top: 5%;
    transform: translate(-50%, -5%);
    overflow-y: auto;
  }
}